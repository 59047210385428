<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <h1>{{'pages.pricing.header.title' | translate}}</h1>
    </div>

    <!-- Page content -->
    <div class="sections-container">
        <div class="oms-suite">
            <h3>{{'pages.reliefApps.omsSuite.logistics' | translate}}</h3>
            <app-suite-apps-row [showPrice]="true" [tag]="'logistic'"></app-suite-apps-row>
            <h3>{{'pages.reliefApps.omsSuite.management' | translate}}</h3>
            <app-suite-apps-row [showPrice]="true" [tag]="'management'"></app-suite-apps-row>
            <h3>{{'pages.reliefApps.omsSuite.field' | translate}}</h3>
            <app-suite-apps-row [showPrice]="true" [tag]="'fieldOperations'"></app-suite-apps-row>
            <h3>{{'pages.pricing.customApp' | translate}}</h3>
            <app-carrousel type="suiteApps" [typeArg]="{showPrice: true, customApp: true}" [items]="[customApp]"></app-carrousel>
        </div>
    </div>
</div>