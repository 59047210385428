import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../../../../components/shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { Suite } from '../../../../models/suite.model';
import { CommonModule } from '@angular/common';
import { SuitePriceCardComponent } from '../suite-price-card/suite-price-card.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PageNavigationService } from '../../../../services/page-navigation.service';

@Component({
  selector: 'app-suite-pricing',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslateModule, SuitePriceCardComponent, RouterModule, FormsModule],
  templateUrl: './suite-pricing.component.html',
  styleUrl: './suite-pricing.component.scss'
})
export class SuitePricingComponent {
  @Input() suite!: Suite;

  public periodicity: 'yearly' | 'monthly' = 'yearly';

  constructor(private navigationService: PageNavigationService) {}

  public goToContactForm() {
    this.navigationService.scrollTo('contactUs');
  }
}
