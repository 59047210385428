import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export interface Price { 
    name: string,
    description: string,
    yearlyCost: string, // Month cost if paid annually, in EUR
    monthlyCost: string, // Month cost if paid monthly, in EUR
    installation: string // Cost to install, in EUR
}

export interface BasicPrice { // If no costs, we display "contact us for quote"
    name: string,
    description: string
}

export interface Suite {
    code: string,
    icon: string,
    state: 'comingSoon' | 'demoSoon' | 'demoAvailable',
    card: { // Used on the card view, usually uses a shortened title and description
        name: string,
        description: string,
        features: string[]
    }
    name: string,
    description: string,
    image: string,
    properties: {
        title: string,
        icon: string,
        features: string[]
    }[],
    demo?: { // Used for the demo popup.
        text: string,
        image: string
    },
    prices?: ( // Can be undefined, in that case we don't display prices.
        Price | BasicPrice
    )[],
    tags: string[]
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class SuiteAdapter implements Adapter<Suite> {
  constructor() {}

  /**
   * the adapt function
   *
   * @param item the item to adapt into project
   * @returns Project
   */
  adapt(item: any): Suite {
    console.log(item);
    const suite: Suite = {
        code: item.code,
        icon: item.icon,
        state: item.state,
        card: {
            name: item.cards[0].name,
            description: item.cards[0].description,
            features: item.cards[0].features.map((feature: any) => feature.feature)
        },
        name: item.name,
        description: item.description,
        image: item.image,
        properties: item.properties.map((prop: any) => {
            prop.features = prop.features.map((ft: any) => ft.individual_feature);
            return prop;
        }),
        demo: item.demo[0],
        prices: item.prices,
        tags: item.tags
    }
    return suite;
  }
}
