import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { AppsCarrouselComponent } from '../../components/apps-carrousel/apps-carrousel.component';
import { HowItWorksComponent } from '../../components/how-it-works/how-it-works.component';
import { ButtonComponent } from '../../components/shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { SuitePricingComponent } from './components/suite-pricing/suite-pricing.component';
import { Suite } from '../../models/suite.model';
import { SuiteService } from '../../services/suite.service';
import { PageNavigationService } from '../../services/page-navigation.service';

@Component({
  selector: 'app-suite',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    AppsCarrouselComponent,
    HowItWorksComponent,
    ButtonComponent,
    TranslateModule,
    RouterModule,
    SuitePricingComponent
  ],
  templateUrl: './suite.component.html',
  styleUrl: './suite.component.scss'
})


export class SuiteComponent {
  private subscription = new Subscription();
  private loadingSuites = true;

  public suiteCode: any;
  public suite!: Suite;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private suiteService: SuiteService,
    private navigationService: PageNavigationService
  ) {
    this.subscription.add(this.suiteService.suites.subscribe(suites => {
      if (suites) {
        this.loadingSuites = false;
        this.setSuite();
      }
    }))
    this.subscription.add(this.route.params.subscribe(params => {
      this.suiteCode = params['id'];
      this.setSuite();
    }));
  }

  private setSuite() {
    if (!this.loadingSuites && this.suiteCode) {
      let suite = this.suiteService.getSuite(this.suiteCode);
      if (!suite) {
        this.router.navigate(['relief-apps'])
      } else {
        this.suite = suite;
        console.log(suite);
      }
    }
  }

  public goToContactForm() {
    this.navigationService.scrollTo('contactUs');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
