import { Component, Input } from '@angular/core';
import { BasicPrice, Price } from '../../../../models/suite.model';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../../../components/shared/button/button.component';
import { PageNavigationService } from '../../../../services/page-navigation.service';

@Component({
  selector: 'app-suite-price-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './suite-price-card.component.html',
  styleUrl: './suite-price-card.component.scss'
})
export class SuitePriceCardComponent {
  @Input() info!: Price | BasicPrice;
  @Input() periodicity: 'yearly' | 'monthly' = 'monthly';

  constructor(
    private navigationService: PageNavigationService
  ) {}

  public get prices () {
    if (Object.hasOwn(this.info, 'installation')) {
      return this.info as any;
    } else {
      return undefined;
    }
  };

  public goToContactForm() {
    this.navigationService.scrollTo('contactUs', ['/home']);
  }
}
