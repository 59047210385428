import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { TermsOfServicesComponent } from './pages/other/terms-of-services/terms-of-services.component';
import { PrivacyPolicyComponent } from './pages/other/privacy-policy/privacy-policy.component';
import { ReliefAppsComponent } from './pages/relief-apps/relief-apps.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AboutComponent } from './pages/about/about.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ProjectComponent } from './pages/project/project.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { SuiteComponent } from './pages/suite/suite.component';

export const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'consulting', component: ConsultingComponent },
    { path: 'consulting/:service', component: ConsultingComponent },
    { path: 'relief-apps', component: ReliefAppsComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'projects/:id', component: ProjectComponent },
    { path: 'suites/:id', component: SuiteComponent },
    { path: 'about', component: AboutComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'partners', component: PartnersComponent },
    { path: 'terms-of-services', component: TermsOfServicesComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', redirectTo: '/home' }, 
];
