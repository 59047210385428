import { Component, ElementRef, ViewChild } from '@angular/core';
import { MemberService } from '../../../../services/members.service';
import { Member } from '../../../../models/member.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-meet-our-team',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './meet-our-team.component.html',
  styleUrl: './meet-our-team.component.scss'
})
export class MeetOurTeamComponent {
  public members: Member[] = [];
  
  private scrollHandle = 0;
  private scrollStep = 5;

  @ViewChild('membersList') membersList!: ElementRef;

  constructor(public memberService: MemberService) {
    this.memberService.members.subscribe(res => {
      this.members = res.sort((a, b) => a.order - b.order);
    })
  }

  scrollLeft(){
    this.membersList.nativeElement.scrollLeft -= 150;
  }

  scrollRight(){
    this.membersList.nativeElement.scrollLeft += 150;
  }
}
