<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <div class="flex">
            <div>
                <h4>{{'pages.about.header.miniTitle' | translate}}</h4>
                <h1>{{'pages.about.header.title' | translate}}</h1>
                <p>{{'pages.about.header.summary1' | translate}}</p>
                <p>{{'pages.about.header.summary2' | translate}}</p>
                <app-button [style]="'outline'" [color]="'black'" [arrow]="true">
                    {{'component.contactUs.summary' | translate}}
                </app-button>
            </div>
            <div>
                <div class="about-chips">
                    <div class="chip">
                        <div class="icon">
                            <mat-icon aria-hidden="true" svgIcon="people"></mat-icon>
                        </div>
                        <p>{{'pages.about.header.chip.0' | translate}}</p>
                    </div>
                    <div class="chip">
                        <div class="icon">
                            <mat-icon aria-hidden="true" svgIcon="medal"></mat-icon>
                        </div>
                        <p>{{'pages.about.header.chip.1' | translate}}</p>
                    </div>
                    <div class="chip">
                        <div class="icon">
                            <mat-icon aria-hidden="true" svgIcon="hearts"></mat-icon>
                        </div>
                        <p>{{'pages.about.header.chip.2' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div class="sections-container">
        <div class="highlights">
            <div class="flex">
                <div *ngFor="let highlight of highlights">
                    <div class="icon">
                        <mat-icon aria-hidden="true" [svgIcon]="highlight.icon"></mat-icon>
                    </div>
                    <h3>{{highlight.title| translate}}</h3>
                    <p [innerHTML]="highlight.content| translate"></p>
                </div>
            </div>
            <div class="bg"></div>
        </div>
        <div class="principles flex">
            <div>
                <h2>{{'pages.about.principles.title' | translate}}</h2>
            </div>
            <div>
                <div *ngFor="let principle of principles">
                    <p>
                        <span>{{principle.title | translate}}: </span>
                        {{principle.content | translate}}
                    </p>
                </div>
            </div>
        </div>

        <app-meet-our-team></app-meet-our-team>
        <app-hiring-section></app-hiring-section>
        <app-board-members></app-board-members>

        <!-- "Contact us" section -->
        <app-contact-us id="contactUs"></app-contact-us>
    </div>
</div>