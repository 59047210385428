// const getTranslationKeyForServices = (key: string, serviceKey: string, section?: number) => {
//     let translationKey = `pages.consulting.service.${serviceKey}`
//     if (typeof section == 'undefined') {
//         translationKey += `.${key}`;
//     } else {
//         translationKey += `.section.${section}.${key}`;
//     }
//     return translationKey;
// }
// const getSections = (serviceKey: string, n: number) => {
//     const sections: {title: string, summary: string}[] = [];
//     for(let i = 0; i < n; i++) {
//         sections.push({
//             title: getTranslationKeyForServices('title', serviceKey, i),
//             summary: getTranslationKeyForServices('summary', serviceKey, i)
//         });
//     }
//     return sections;
// }

/**
 * Object defining needed properties of a navigation item.
 * 
 * - key: Used to get the correct translation for the item and as identificator.
 * - nav: Used as the route of the item.
 * - children: Used if the item has sub-routes available.
 */
interface Services {
    [key: string]: {
        title: string,
        summary: string,
        image: string,
        sections: {
            title: string
            summary: string
        }[]
    }
}

// /**
//  * Make sure there is an entry for each consulting item children, name it after its 'nav' key
//  */
// export const consultingServices: Services = {
//     'info-management': {
//         image: '/assets/images/pages/consulting/placeholder.png',
//         title: getTranslationKeyForServices('title', 'infoManagement'),
//         summary: getTranslationKeyForServices('summary', 'infoManagement'),
//         sections: getSections('infoManagement', 1)
//     },
// }

export const consultingServices: Services = {
    'info-management': {
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'Information Management',
        summary: `
            Streamline data management to enhance crisis response.
            <ul>
                <li>Streamline data collection, storage, analysis and visualization to enhance response efforts.</li>
                <li>Design and implement data management systems tailored to organizational needs.</li>
                <li>Ensure data integrity and security for accurate and reliable information in crisis situations.</li>
            </ul>`,
        sections: [{
            title: 'Data Collection',
            summary: `
                <ul>
                    <li>Implement efficient data collection methods for accurate field data.</li>
                    <li>Use mobile and web applications, offline and online, to streamline data entry.</li>
                    <li>Ensure timely and reliable data gathering in crisis situations.</li>
                    <li>Tools: Kobo/ODK, CommCare, Survey CTO, ActivityInfo, Limesurvey </li>
                </ul>`
        },{
            title: 'Data Analysis',
            summary: `
                <ul>
                    <li>Analyze collected data to generate actionable insights.</li>
                    <li>Use statistical tools to identify trends and patterns.</li>
                    <li>Provide data-driven recommendations for program improvement.</li>
                    <li>Tools: R, Python, Excel, Stata, SPSS, DHIS2.</li>
                </ul>`
        },{
            title: 'Data Visualization',
            summary: `
                <ul>
                    <li>Create clear and informative visual representations of data.</li>
                    <li>Use charts, graphs, and maps to communicate findings.</li>
                    <li>Simplify complex data for better understanding and decision-making.</li>
                    <li>Tools: PowerBi, Metabase, Tableau.</li>
                </ul>`
        },{
            title: 'Information Management Systems',
            summary: `
                <ul>
                    <li>Design and implement systems to manage information effectively.</li>
                    <li>Integrate various data sources into a unified platform.</li>
                    <li>Ensure real-time data access and updates for field operations.</li>
                </ul>`
        },{
            title: 'Data Security and Protection',
            summary: `
                <ul>
                    <li>Implement measures to secure sensitive data.</li>
                    <li>Ensure compliance with data protection regulations.</li>
                    <li>Provide training on data security best practices for staff.</li>
                </ul>`
        },{
            title: 'GIS (Geographic Information Systems)',
            summary: `
                <ul>
                    <li>Use GIS to map and analyze spatial data.</li>
                    <li>Support geographic planning and resources allocation for any sectorial operations.</li>
                    <li>Enhance situational awareness with real-time geographic information.</li>
                    <li>Tools: ArcGIS, QGIS, Google Earth Engine.</li>
                </ul>`
        }]
    },
    'meal-and-research': { 
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'Capacity Building & Training',
        summary: `
            Develop and deliver training programs to boost staff capabilities.
            <ul>
                <li>Develop customized training programs to enhance staff skills and knowledge.</li>
                <li>Provide hands-on workshops and e-learning modules focused on relevant contexts.</li>
                <li>Facilitate organizational growth and resilience through strategic capacity building.</li>
            </ul>`,
        sections: [{
            title: 'Topics',
            summary: `
                <ul>
                    <li>Enhance skills in project management, data analysis, and field operations.</li>
                    <li>Provide training on the latest tech tools and humanitarian practices such as Needs Assessment training or MEAL.</li>
                    <li>Focus on strategic planning, leadership, and organizational development.</li>
                </ul>`
        },{
            title: 'Formats',
            summary: `
                <ul>
                    <li>Remote</li>
                    <li>Face to face</li>
                    <li>Workshops</li>
                    <li>Seminars</li>
                    <li>Coaching</li>
                    <li>User manuals</li>
                    <li>Videos</li>
                    <li>E-learnings ...</li>
                </ul>`
        }]
    },
    'training-and-capacity': {
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'MEAL & Research',
        summary: `
            Implement evaluation frameworks and conduct research to improve project impact.
            <ul>
                <li>Design and implement robust Monitoring, Evaluation, Accountability, and Learning frameworks for projects.</li>
                <li>Conduct comprehensive research and analysis to inform program development.</li>
                <li>Generate actionable insights to improve project outcomes and impact on the field.</li>
            </ul>`,
        sections: [{
            title: 'Business Analysis / Needs Assessment',
            summary: `
                <ul>
                    <li>Conduct thorough assessments to identify organizational needs.</li>
                    <li>Analyze gaps and opportunities for improvement.</li>
                    <li>Provide tailored recommendations for strategic development.</li>
                </ul>`
        },{
            title: 'Reporting',
            summary: `
                <ul>
                    <li>Create clear and concise reports on project outcomes.</li>
                    <li>Present data and insights in an accessible format.</li>
                    <li>Ensure timely and accurate reporting to stakeholders.</li>
                </ul>`
        },{
            title: 'Research',
            summary: `
                <ul>
                    <li>Conduct comprehensive research to inform program strategies.</li>
                    <li>Analyze data to support evidence-based decision-making.</li>
                    <li>Provide detailed reports on research findings and implications.</li>
                </ul>`
        },{
            title: 'Advisory Services (Strategic Advice for Tech and Innovation)',
            summary: `
                <ul>
                    <li>Offer expert advice on tech and innovation strategies.</li>
                    <li>Guide organizations in implementing innovative solutions.</li>
                    <li>Support long-term strategic planning and development.</li>
                </ul>`
        },{
            title: 'Evaluation',
            summary: `
                <ul>
                    <li>Design and conduct evaluations to measure project impact.</li>
                    <li>Use evaluation findings to improve program effectiveness.</li>
                    <li>Provide actionable recommendations based on evaluation results.</li>
                </ul>`
        }]
    },
    'app-dev-and-maintenance': { 
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'Application Development & Maintenance',
        summary: `
            Create and maintain custom applications.
            <ul>
                <li>Create custom applications tailored to specific organizational needs.</li>
                <li>Provide ongoing maintenance and updates to ensure optimal performance in challenging environments.</li>
                <li>Enhance user experience through intuitive design and functionality, supporting field operations.</li>
            </ul>`,
        sections: [{
            title: 'Website Design and Development',
            summary: `
                <ul>
                    <li>Create custom websites tailored to your organization's needs.</li>
                    <li>Develop responsive and user-friendly interfaces.</li>
                    <li>Ensure seamless integration with existing systems.</li>
                </ul>`
        },{
            title: 'Maintenance',
            summary: `
                <ul>
                    <li>Provide ongoing support and updates for optimal website performance.</li>
                    <li>Monitor and resolve technical issues promptly.</li>
                    <li>Ensure website security and data protection.</li>
                </ul>`
        },{
            title: 'SEO',
            summary: `
                <ul>
                    <li>Optimize websites to improve search engine rankings.</li>
                    <li>Implement best practices for on-page and off-page SEO.</li>
                    <li>Drive organic traffic to increase visibility and engagement.</li>
                </ul>`
        },{
            title: 'User Experience (UX/UI) Design',
            summary: `
                <ul>
                    <li>Design intuitive and engaging user interfaces.</li>
                    <li>Conduct usability testing to enhance user experience.</li>
                    <li>Ensure accessibility and ease of navigation for all users.</li>
                </ul>`
        }]
    },
    'AI-and-tools': { 
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'AI & Interactive Tools',
        summary: `
            Integrate AI and develop interactive tools to improve efficiency and decision-making.
            <ul>
                <li>Integrate artificial intelligence to automate processes and improve efficiency.</li>
                <li>Develop interactive tools such as chatbots and voice-enabled systems to support field staff.</li>
                <li>Leverage AI to provide real-time insights and predictive analytics for better decision-making.</li>
            </ul>`,
        sections: [{
            title: 'Chatbots & Voice Assistance',
            summary: `
                <ul>
                    <li>Develop AI-driven chatbots for real-time user support.</li>
                    <li>Create voice-assisted systems to facilitate hands-free operations.</li>
                    <li>Enhance communication and accessibility for field staff and beneficiaries.</li>
                </ul>`
        },{
            title: 'AI Solutions',
            summary: `
                <ul>
                    <li>Integrate AI to automate routine tasks and improve efficiency.</li>
                    <li>Implement predictive analytics for better decision-making.</li>
                    <li>Develop machine learning models to analyze and interpret large datasets.</li>
                </ul>`
        }]
    },
    'mobile-web-hybrid': { 
        image: '/assets/images/pages/consulting/placeholder.png',
        title: 'Mobile, Web & Hybrid',
        summary: `
            Design and deploy responsive mobile and web applications for diverse field settings.
            <ul>
                <li>Develop responsive mobile and web applications to support efforts in diverse settings.</li>
                <li>Create hybrid solutions that offer the best of both native and web technologies for field operations.</li>
                <li>Ensure seamless integration and user-friendly interfaces to enhance accessibility and usability for teams.</li>
            </ul>`,
        sections: [{
            title: 'Mobile Applications',
            summary: `
                <ul>
                    <li>Develop responsive mobile apps for seamless use in field operations.</li>
                    <li>Ensure offline functionality to accommodate low-connectivity areas.</li>
                    <li>Integrate geolocation features for real-time tracking and mapping.</li>
                </ul>`
        },{
            title: 'Web Platforms',
            summary: `
                <ul>
                    <li>Design user-friendly web platforms for information sharing and collaboration.</li>
                    <li>Optimize for scalability to handle varying levels of traffic and users.</li>
                    <li>Implement secure login and access controls for data protection.</li>
                </ul>`
        },{
            title: 'Hybrid Solutions',
            summary: `
                <ul>
                    <li>Create hybrid applications that combine the advantages of both web and native mobile apps.</li>
                    <li>Provide cross-platform compatibility for wider accessibility.</li>
                    <li>Enable synchronization between web and mobile versions for seamless user experience.</li>
                </ul>`
        }]
    }
}