<div class="flex">
    <h2>Meet our team</h2>
    <div>
        <button mat-icon-button>
            <mat-icon (click)="scrollLeft()">chevron_left</mat-icon>
        </button>
        <button mat-icon-button>
            <mat-icon (click)="scrollRight()">chevron_right</mat-icon>
        </button>
    </div>
</div>
<div class="member-list" #membersList>
    <div class="card" *ngFor="let member of members">
        <div class="portrait" [style.background-image]="'url(' + member.img + ')'"></div>
        <div class="info">
            <h4>{{member.name}}</h4>
            <p>{{member.role}}</p>
        </div>
        <div class="quote">
            <mat-icon aria-hidden="true" svgIcon="quote"></mat-icon>
            <p>{{member.content}}</p>
        </div>
    </div>
</div>