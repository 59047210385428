import { Component } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../models/project.model';
import { CommonModule } from '@angular/common';
import { CustomListComponent } from '../home/components/custom-list/custom-list.component';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { ProjectsRowComponent } from '../../components/projects-row/projects-row.component';
import { AppsCarrouselComponent } from '../../components/apps-carrousel/apps-carrousel.component';
import { ImageCarouselComponent } from "../../components/image-carousel/image-carousel.component";

const SDGS = {
  1: '/assets/images/sdgs/01.png',
  2: '/assets/images/sdgs/02.png',
  3: '/assets/images/sdgs/03.png',
  4: '/assets/images/sdgs/04.png',
  5: '/assets/images/sdgs/05.png',
  6: '/assets/images/sdgs/06.png',
  7: '/assets/images/sdgs/07.png',
  8: '/assets/images/sdgs/08.png',
  9: '/assets/images/sdgs/09.png',
  10: '/assets/images/sdgs/10.png',
  11: '/assets/images/sdgs/11.png',
  12: '/assets/images/sdgs/12.png',
  13: '/assets/images/sdgs/13.png',
  14: '/assets/images/sdgs/14.png',
  15: '/assets/images/sdgs/15.png',
  16: '/assets/images/sdgs/16.png',
  17: '/assets/images/sdgs/17.png',
}

@Component({
  selector: 'app-project',
  standalone: true,
  imports: [CommonModule, CustomListComponent, ProjectsRowComponent, ContactUsComponent, AppsCarrouselComponent, ImageCarouselComponent],
  templateUrl: './project.component.html',
  styleUrl: './project.component.scss'
})
export class ProjectComponent {
  private subscription = new Subscription();
  private id?: string;
  private projects: Project[] = []
  
  public project?: Project;
  public services: String[] = [];
  public images: String[] = [];
  public sdgs: any = SDGS;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscription.add(this.route.params.subscribe(params => {
      window.scrollTo(0, 0);
      this.id = params['id'];
      this.setProject();
    }));
    this.subscription.add(this.projectService.projects.subscribe((res) => {
      if (res.length > 0) {
        this.projects = res;
        this.setProject();
      }
    }));
  }

  private setProject() {
    // Get project once projects load
    if (this.projects.length > 0 && this.id) {
      this.project = this.projectService.getProject(this.id);

      // Go to project list if project doesn't exist
      if (!this.project) {
        this.router.navigate(['projects']);
      }

      // Set project images
      this.images = [];
      for (let item of this.project!.gallery) {
        if (item.image) {
          this.images.push(item.image);
        }
      }

      // Parse services
      this.services = this.project!.details.service.split(/[;,]\s*/gm);

      console.log(this.project);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
