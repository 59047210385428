import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SuiteAppsRowComponent } from '../../components/suites-row/suite-apps-row.component';
import { CarrouselComponent } from '../../components/shared/carrousel/carrousel.component';

const CUSTOM_APP: any = 
{
    code: 'CUSTOM',
    icon: "/assets/images/apps/rms/icon.png",
    card: {
      name: 'Design your Custom App!',
      description: "Share your project details with us, and we'll design & develop a Data Management solution tailored to your specific needs and workflow. With Relief Apps' No-Code application builder, you benefit from faster, simplified development & configuration.",
      features: [],
    },
    state: 'comingSoon',
    prices: []
};

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [CommonModule, TranslateModule, SuiteAppsRowComponent, CarrouselComponent],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent {
  public customApp = CUSTOM_APP;
}
