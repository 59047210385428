<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <div class="flex">
            <div>
                <h4>{{'pages.reliefApps.header.miniTitle' | translate}}</h4>
                <h1>{{'pages.reliefApps.header.title' | translate}}</h1>
            </div>
            <div>
                <p>{{'pages.reliefApps.header.summary' | translate}}</p>
            </div>
        </div>
        <div class="banner">
            <div [style.background-image]="'url(' + '/assets/images/apps/wms/cover.png' + ')'"></div>
            <div [style.background-image]="'url(' + '/assets/images/apps/cms/cover.png' + ')'"></div>
            <div [style.background-image]="'url(' + '/assets/images/apps/rms/cover.png' + ')'"></div>
            <div [style.background-image]="'url(' + '/assets/images/apps/custom/cover.png' + ')'"></div>
        </div>
    </div>

    <!-- Page content -->
    <div class="sections-container">
        <div class="oms-suite">
            <h2>{{'pages.reliefApps.omsSuite.title' | translate}}</h2>
            <h3>{{'pages.reliefApps.omsSuite.logistics' | translate}}</h3>
            <app-suite-apps-row [tag]="'logistic'"></app-suite-apps-row>
            <h3>{{'pages.reliefApps.omsSuite.management' | translate}}</h3>
            <app-suite-apps-row [tag]="'management'"></app-suite-apps-row>
            <h3>{{'pages.reliefApps.omsSuite.field' | translate}}</h3>
            <app-suite-apps-row [tag]="'fieldOperations'"></app-suite-apps-row>
        </div>

        <!-- "How it works" section -->
         <app-how-it-works></app-how-it-works>

         <div class="pricing">
            <div class="flex">
               <h2>{{'shared.pricing' | translate}}</h2>
               <div>
                   {{'component.suiteAppsRow.text' | translate}}
                   <app-button [style]="'basic'" [color]="'accent'" [arrow]="true" [routerLink]="'/pricing'">
                       {{'component.suiteAppsRow.seeAll' | translate}}
                   </app-button>
               </div>
           </div>
           <app-suite-apps-row [showPrice]="true"></app-suite-apps-row>
         </div>

         <!-- "Projects" section -->
         <app-projects-row></app-projects-row>

        <!-- "Contact us" section -->
        <app-contact-us></app-contact-us>
    </div>
</div>