import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TranslateService } from '@ngx-translate/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { FooterComponent } from './components/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent, MatIconModule, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
    translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Translation setup
    translate.setDefaultLang('en');
    translate.use('en');

    // Adding new icons
    this.matIconRegistry.addSvgIcon(
      `chevron_down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `minimal_arrow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/minimal-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `heart_circle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heart-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `magic_star`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/magic-star.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `eye`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `people`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/people.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `monitor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/monitor.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `programming_arrow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/programming-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `tick_circle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tick-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `hearts`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/hearts.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `medal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/medal.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `quote`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/quote.svg')
    );
  }

  resetPosition() {
    window.scrollTo(0, 0);
  }
}
