import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

/**
 *
 */
export interface ProjectCard {
  id: string;
  title: string;
  description: string;
  list: string[];
  iconUrl: string;
}
/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectCardAdapter implements Adapter<ProjectCard> {
  /**
   * Function for create the different project card
   *
   * @param item the project card to create
   * @returns ProjectCard
   */
  adapt(item: any) {
    const projectCard: ProjectCard = {
      id: item.id,
      title: item.title,
      list: item.list,
      description: item.card_description,
      //list: item.list[0]?.split(';;'), // comes as a list in the backend
      iconUrl: item.iconurl,
    };
    return projectCard;
  }
}
