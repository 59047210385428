<div *ngIf="content">
    <div class="chip">
        <div class="icon" [style.background-image]="'url(' + content.icon + ')'"></div>
        <span>{{content.code.slice(0, 1)}}<span>{{content.code.slice(1)}}</span></span>
    </div>
    
    <div class="content" [class.hover-features]="content.card.features.length > 0">
        <h3>{{content.card.name}}</h3>
        <p>{{content.card.description}}</p>
        <div class="list">
            <div *ngFor="let feature of content.card.features">
                <mat-icon aria-hidden="true" fontIcon="check"></mat-icon>
                <span>{{feature}}</span>
            </div>
        </div>
    </div>
    
    <div class="buttons" [class.pricing]="showPrice">
        <ng-container *ngIf="!isCustom">
            <ng-container *ngIf="!showPrice; else priceInfo">
                <span *ngIf="content.state == 'demoAvailable'; else nonClickable">
                    {{'component.suiteAppCard.bookDemo' | translate}}
                </span>
                <ng-template #nonClickable>
                    <span>{{'component.suiteAppCard.' + content.state | translate}}</span>
                </ng-template>
            </ng-container>
            <ng-template #priceInfo>
                <div *ngIf="content.prices && content.prices[0] && $any(content.prices[0]).yearlyCost">
                    <p>Starting from</p>
                    <h3>{{$any(content.prices[0]).yearlyCost}} /month</h3>
                </div>
            </ng-template>
        </ng-container>
        <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" *ngIf="!isCustom; else customAppButton" [routerLink]="'/suites/' + content.code">
            {{'shared.learn' | translate}}
        </app-button>
        <ng-template #customAppButton>
            <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" class="custom-button" (click)="goToContactForm()">
                {{'pages.pricing.contactUs' | translate}}
            </app-button>
        </ng-template>
    </div>
</div>