<div *ngIf="suite.prices && suite.prices.length > 0">
    <div class="flex title" *ngIf="suite">
        <h2>{{suite.code}} Pricing</h2>
        <app-button [style]="'outline'" [color]="'black'" [arrow]="true" [routerLink]="'/pricing'">
            Pricing
        </app-button>
    </div>
    <div class="periodicity-selector">
        <label class="container">Pay Annually
          <input type="radio" [(ngModel)]="periodicity" [value]="'yearly'">
          <span class="checkmark"></span>
        </label>
        <label class="container">Pay Monthly
          <input type="radio" [(ngModel)]="periodicity" [value]="'monthly'">
          <span class="checkmark"></span>
        </label>
    </div>
    <div class="card-list">
        <app-suite-price-card *ngFor="let price of suite.prices" [info]="price" [periodicity]="periodicity"></app-suite-price-card>
    </div>
</div>