import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';


/**
 * Icon has to be one of the custom svg defined on "app.component.ts"
 * Title and content have to be translation keys.
 */
export type CustomListItem = {icon?: string, title: string, content: string};

@Component({
  selector: 'app-custom-list',
  standalone: true,
  imports: [CommonModule, MatIcon, TranslateModule],
  templateUrl: './custom-list.component.html',
  styleUrl: './custom-list.component.scss'
})
export class CustomListComponent implements OnInit, OnChanges {

  /**
   * Used to define the style used by the component
   */
  @Input() variant: 'gray' | 'red' | 'simple-red' | 'small-red' = 'gray'
  /**
   * Used to define the content of the component.
   */
  @Input() content: CustomListItem[] | string[] = [];

  public complexList: CustomListItem[] = [];
  public simpleList: string[] = [];

  ngOnInit(): void {
    this.setList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setList();
  }

  private setList() {
    if (this.content && this.content.length > 0) {
      if (typeof this.content[0] === 'string') {
        this.simpleList = this.content as string[];
        this.complexList = [];
      } else {
        this.simpleList = [];
        this.complexList = this.content as CustomListItem[];
      }
    }
  }
}
