<div class="icon-list" [ngClass]="[variant]" *ngIf="complexList">
    <div *ngFor="let item of complexList; index as i">
        <div>
            <mat-icon *ngIf="item.icon" aria-hidden="true" [svgIcon]="item.icon"></mat-icon>
            <span *ngIf="!item.icon">{{("0" + (i + 1)).slice(-2)}}.</span>
        </div>
        <div>
            <h4>{{item.title | translate}}</h4>
            <p>{{item.content | translate}}</p>
        </div>
    </div>
    <div *ngFor="let item of simpleList; index as i">
        <div>
            <span>{{("0" + (i + 1)).slice(-2)}}.</span>
        </div>
        <div>
            <p class="simple-text">{{item | translate}}</p>
        </div>
    </div>
</div>