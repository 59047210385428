import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { Project } from '../../../models/project.model';
import { MatIconModule } from '@angular/material/icon';
import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
import { ProjectCardComponent } from '../project-card/project-card.component';
import { SuiteAppCardComponent } from '../suite-app-card/suite-app-card.component';

const DEFAULT_NUMBER_OF_PAGES = 3;
const DEFAULT_ITEMS_PER_PAGE = 3;

function FadeInOut(timingIn: number, timingOut: number): AnimationTriggerMetadata  {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({display: 'none', opacity: 0}),
      animate(`${timingIn}ms ${timingOut}ms`, style({ opacity: 1, 'display': 'block' })),
    ]),
    transition(':leave', [
      style({}),
      animate(`${timingOut}ms`, style({ opacity: 0, 'display': 'none' })),
    ])
  ]);
}

@Component({
  selector: 'app-carrousel',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslateModule, ProjectCardComponent, SuiteAppCardComponent, MatIconModule],
  animations: [FadeInOut(300, 300)],
  templateUrl: './carrousel.component.html',
  styleUrl: './carrousel.component.scss'
})
export class CarrouselComponent implements OnChanges {

  @Input() numberOfPages?: number;
  @Input() itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  @Input() type: 'projects' | 'suiteApps' = 'projects';
  @Input() typeArg?: {showPrice?: boolean, customApp?: boolean};
  @Input() items: Project[] | any[] = [];

  public availablePages = 1;
  public currentPage = 0;

  public renderedItems: any[] = [];

  /**
   * 
   */
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    // We adjust the page quantity if there are less projects than required
    if (this.items.length > 0) {
      if (!this.numberOfPages || this.items.length / this.itemsPerPage < this.numberOfPages) {
        this.availablePages = Math.ceil(this.items.length / this.itemsPerPage);
      } else {
        this.availablePages = this.numberOfPages;
      }
    }
    this.setPageContent();
  }

  /**
   * Sets the renderedProjects to the correct ones based on the vurrent page and the projects per page
   */
  private setPageContent() {
    this.renderedItems = this.items
      .slice(this.currentPage * this.itemsPerPage, this.currentPage * this.itemsPerPage + this.itemsPerPage);
  }

  /**
   * Changes the current page. First page is 0.
   * 
   * @param page Number indicating the page to change.
   */
  public changePage(page: number) {
    if (page >= this.availablePages || page < 0) {
      return;
    }
    this.currentPage = page;
    this.setPageContent();
  }

  public getMaxCardSize(padding: number): string {
    return `calc((100% - ${(this.itemsPerPage - 1) * padding}px) / ${this.itemsPerPage})`
  }
}
