import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CarrouselComponent } from "../shared/carrousel/carrousel.component";
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Suite } from '../../models/suite.model';
import { SuiteService } from '../../services/suite.service';

@Component({
  selector: 'app-suite-apps-row',
  standalone: true,
  imports: [CarrouselComponent, ButtonComponent, TranslateModule, CommonModule],
  templateUrl: './suite-apps-row.component.html',
  styleUrl: './suite-apps-row.component.scss'
})
export class SuiteAppsRowComponent implements OnChanges {
  @Input() showPrice = false;
  @Input() tag?: string;
  public suites: Suite[] = [];
  public filteredSuites: Suite[] = [];

  constructor(private suiteService: SuiteService) {
    this.suiteService.suites.subscribe(suites => {
      if (suites) {
        this.suites = suites;
        this.filteredSuites = this.filterSuites();
      }
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.filteredSuites = this.filterSuites();
  }

  private filterSuites(): Suite[] {
    return this.suites.filter(suite => this.tag ? suite.tags.includes(this.tag) : true);
  }
}
