import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PageNavigationService } from '../../../services/page-navigation.service';
import { RouterModule } from '@angular/router';
import { Suite } from '../../../models/suite.model';

@Component({
  selector: 'app-suite-app-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatIconModule, TranslateModule, RouterModule],
  templateUrl: './suite-app-card.component.html',
  styleUrl: './suite-app-card.component.scss'
})
export class SuiteAppCardComponent {
  @Input() content?: Suite;
  @Input() showPrice = false;
  @Input() isCustom = false;

  constructor(
    private navigationService: PageNavigationService
  ) {}

  public goToContactForm() {
    this.navigationService.scrollTo('contactUs', ['/home']);
  }
}
