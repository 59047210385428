<div class="cards-container">
    <ng-container *ngFor="let item of renderedItems">
        <app-project-card
            *ngIf="type == 'projects'"
            [content]="item"
            [style.max-width]="getMaxCardSize(20)"
            [@fadeInOut]
        ></app-project-card>
        <app-suite-app-card
            *ngIf="type === 'suiteApps'"
            [showPrice]="!!(typeArg && typeArg.showPrice)"
            [isCustom]="!!(typeArg && typeArg.customApp)"
            [content]="item"
            [style.max-width]="getMaxCardSize(20)"
            [@fadeInOut]
        ></app-suite-app-card>
    </ng-container>
    <div class="button prev" *ngIf="currentPage > 0">
        <mat-icon aria-hidden="true" fontIcon="chevron_left" (click)="changePage(currentPage - 1)"></mat-icon>
    </div>
    <div class="button next" *ngIf="currentPage < availablePages - 1">
        <mat-icon aria-hidden="true" fontIcon="chevron_right" (click)="changePage(currentPage + 1)"></mat-icon>
    </div>
</div>
<div class="page-indicator" *ngIf="availablePages > 1">
    <div *ngFor="let item of [].constructor(availablePages); let i= index" [class.active]="currentPage == i"></div>
</div>