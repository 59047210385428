<div class="page-container" *ngIf="suite">

    <!-- Page header -->
    <div class="header">
        <div class="chip">
            <div class="icon" [style.background-image]="'url(' + suite.icon + ')'"></div>
            <span>{{suite.code.slice(0, 1)}}<span>{{suite.code.slice(1)}}</span></span>
        </div>
        <div class="flex">
            <div>
                <h2>{{suite.name}}</h2>
            </div>
            <div>
                <p>{{suite.description}}</p>
            </div>
        </div>
    </div>
    <div class="summary">
        <div class="flex">
            <div class="image">
                <img [src]="suite.image && suite.image.length > 0 ? suite.image : '/assets/images/pages/suite/placeholder.png'">
            </div>
            <div class="list">
                <div *ngFor="let property of suite.properties">
                    <h3>{{property.title}}</h3>
                    <ul>
                        <li *ngFor="let feature of property.features">{{feature}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div class="sections-container">

        <div class="demo-section" *ngIf="suite.state === 'demoAvailable' && suite.demo">
            <div class="info">
                <h2>Discover {{suite.code}} for free</h2>
                <p>{{suite.demo.text}}</p>
                <app-button [style]="'flat'" [color]="'black'" [arrow]="true" (click)="goToContactForm()">Free Trial</app-button>
            </div>
            <div class="image" [style.background-image]="'url(' + suite.demo.image + ')'">
                <!-- <img [src]="'/assets/images/pages/suite/placeholder.png'"> -->
            </div>
        </div>

        <app-apps-carrousel></app-apps-carrousel>

        <!-- "How it works" section -->
         <app-how-it-works></app-how-it-works>
        
        <!-- Pricing section -->
        <app-suite-pricing [suite]="suite" *ngIf="suite.prices && suite.prices.length > 0"></app-suite-pricing>

        <!-- "Contact us" section -->
        <app-contact-us id="contactUs"></app-contact-us>
    </div>
</div>