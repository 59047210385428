import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { HowItWorksComponent } from '../../components/how-it-works/how-it-works.component';
import { CarrouselComponent } from '../../components/shared/carrousel/carrousel.component';
import { ProjectsRowComponent } from "../../components/projects-row/projects-row.component";
import { SuiteAppsRowComponent } from "../../components/suites-row/suite-apps-row.component";
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../components/shared/button/button.component';
import { RouterModule } from '@angular/router';
import { SuiteService } from '../../services/suite.service';
import { Suite } from '../../models/suite.model';

@Component({
  selector: 'app-relief-apps',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    HowItWorksComponent,
    ProjectsRowComponent,
    SuiteAppsRowComponent,
    TranslateModule,
    ButtonComponent,
    RouterModule,
  ],
  templateUrl: './relief-apps.component.html',
  styleUrl: './relief-apps.component.scss'
})
export class ReliefAppsComponent {

  constructor() {}
}
