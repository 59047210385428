
<h3 style="margin-bottom: 6px;">{{info.name}}</h3>
<p style="margin-bottom: 32px; line-height: 30px;">{{info.description}}</p>
<ng-container *ngIf="prices; else custom">
    <h3 class="accent" style="margin-bottom: 4px;">
        €{{prices[periodicity + 'Cost']}}/month
    </h3>
    <p style="margin-bottom: 20px;">Billed {{periodicity}}</p>
    <h3 style="margin-bottom: 20px;">+</h3>
    <p style="margin-bottom: 2px;">Starting at</p>
    <h4 class="accent" style="font-weight: normal;">
        €{{prices.installation}}/Installation
    </h4>
</ng-container>
<ng-template #custom>
    <h4 class="accent" style="margin-bottom: 40px; margin-top: 4px; max-width: 200px;">Contact us for a quote</h4>
    <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" (click)="goToContactForm()">
        Let's Talk
    </app-button>
</ng-template>