import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Project, ProjectAdapter } from '../models/project.model';
import { environment } from '../../environments/environment';
import { Suite, SuiteAdapter } from '../models/suite.model';

/**
 *
 */
const SUITES_ROUTE = '/suites';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class SuiteService {
  private $suites = new BehaviorSubject<Suite[] | undefined>(undefined);

  /**
   * The constructor
   *
   * @param api the api service
   * @param suiteAdapter the adapter project service
   */
  constructor(private api: ApiService, private suiteAdapter: SuiteAdapter) {
    const url = environment.apiUrl + SUITES_ROUTE;
    this.api.get(url).subscribe((res) => {
      this.$suites.next(res.map((x: any) => this.suiteAdapter.adapt(x)));
    });
  }

  /**
   * Get the observable of all project
   *
   * @returns Observable<Project>
   */
  get suites(): Observable<Suite[] | undefined> {
    return this.$suites.asObservable();
  }

  /**
   * Get a project with it id
   *
   * @param id the id of the project
   * @returns BehaviorSubject<Project>
   */
  public getSuite(code: string): Suite | undefined {
    const suites = this.$suites.getValue();
    console.log(suites);
    return suites ?
      suites.find((suite) => suite.code === code): 
      undefined;
  }
}
