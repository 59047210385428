<div class="service-bar">
    <div
        *ngFor="let item of serviceItems.children"
        [routerLink]="['/', serviceItems.nav, item.nav]"
        [class.active]="service.nav === item.nav"
    >
        {{item.key | translate}}
    </div>
</div>
<ng-container *ngIf="service.value">
    <div class="img" [style.background-image]="'url(' + service.value.image + ')'"></div>
    <div class="info" [class.flex]="service.value.sections.length > 0">
        <div>
            <h2>{{service.value.title | translate}}</h2>
            <p [innerHTML]="service.value.summary | translate"></p>
        </div>
        <div *ngIf="service.value.sections.length > 0">
                <mat-expansion-panel hideToggle #panel *ngFor="let section of service.value.sections">
                    <mat-expansion-panel-header>
                        <span style="text-align: left;">{{section.title | translate}}</span>
                        <mat-icon aria-hidden="true" [fontIcon]="panel.expanded? 'remove' : 'add'"></mat-icon>
                    </mat-expansion-panel-header>
                    <p [innerHTML]="section.summary | translate"></p>
                </mat-expansion-panel>
        </div>
    </div>
</ng-container>